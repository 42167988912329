// export default `
//     precision highp float;

//     uniform sampler2D tMap;

//     varying vec2 vUv;
//     varying vec3 vNormal;

//     void main() {
//         vec3 normal = normalize(vNormal);
//         vec3 tex = texture2D(tMap, vUv).rgb;

//         vec3 light = normalize(vec3(0.5, 1.0, -0.3));
//         float shading = dot(normal, light) * 0.15;

//         gl_FragColor.rgb = tex + shading;
//         gl_FragColor.a = 1.0;
//     }
//   `

export default `
    precision highp float;
    
    uniform vec2 uImageSizes;
    uniform vec2 uPlaneSizes;
    uniform sampler2D tMap;
    
    varying vec2 vUv;
    
    void main() {
      vec2 ratio = vec2(
        min((uPlaneSizes.x / uPlaneSizes.y) / (uImageSizes.x / uImageSizes.y), 1.0),
        min((uPlaneSizes.y / uPlaneSizes.x) / (uImageSizes.y / uImageSizes.x), 1.0)
      );
    
      vec2 uv = vec2(
        vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
        vUv.y * ratio.y + (1.0 - ratio.y) * 0.5
      );
    
      gl_FragColor.rgb = texture2D(tMap, uv).rgb;
      gl_FragColor.a = 1.0;
    }
  `

export const fragment2 = `
    precision highp float;
    
    uniform vec2 uImageSizes;
    uniform vec2 uPlaneSizes;
    uniform sampler2D tMap;
    
    varying vec2 vUv;
    
    void main() {
      vec2 ratio = vec2(
        min((uPlaneSizes.x / uPlaneSizes.y) / (uImageSizes.x / uImageSizes.y), 1.0),
        min((uPlaneSizes.y / uPlaneSizes.x) / (uImageSizes.y / uImageSizes.x), 1.0)
      );
    
      vec2 uv = vec2(
        vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
        vUv.y * ratio.y + (1.0 - ratio.y) * 0.5
      );
    
      gl_FragColor.rgb = vec3(1.0, 1.0, 1.0);
      gl_FragColor.a = 1.0;
    }
  `
