import { $App } from ".."

export default class {
  app: $App
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createOpener()
  }

  createOpener() {
    const els = document.querySelectorAll<HTMLDivElement>(".about__focus div")
    const imgs = document.querySelectorAll<HTMLDivElement>(
      ".about__focus figure img"
    )
    els.forEach((el, i) => {
      el.onclick = () => {
        if (el.classList.contains("active")) {
          els.forEach((x) => x.classList.remove("active"))
          imgs.forEach((x) => x.classList.remove("active"))
        } else {
          els.forEach((x) => x.classList.remove("active"))
          imgs.forEach((x) => x.classList.remove("active"))
        }
        el.classList.add("active")
        imgs[i].classList.add("active")
      }
    })
  }

  resize() {}

  destroy() {}

  navigate() {}
}
