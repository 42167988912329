// export default `
//     attribute vec2 uv;
//     attribute vec3 position;
//     attribute vec3 normal;

//     uniform mat4 modelViewMatrix;
//     uniform mat4 projectionMatrix;
//     uniform mat3 normalMatrix;

//     varying vec2 vUv;
//     varying vec3 vNormal;

//     void main() {
//         vUv = uv;
//         vNormal = normalize(normalMatrix * normal);

//         gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
//     }
//   `

export default `
    attribute vec2 uv;
    attribute vec3 position;

    uniform mat4 modelViewMatrix;
    uniform mat4 projectionMatrix;

    varying vec2 vUv;

    void main() {
      vUv = uv;

      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `
