import Lenis from "@studio-freight/lenis"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/all"
gsap.registerPlugin(ScrollTrigger)

interface LenisEvent {
  animate: {
    value: number
    from: number
    to: number
    lerp: number
    duration: number
    isRunning: boolean
  }
  animatedScroll: number
  dimensions: { wrapper: Window; content: HTMLElement }
  direction: 1 | -1
  options: { wrapper: Window; content: HTMLElement }
  targetScroll: number
  time: number
  velocity: number
  __isLocked: boolean
  __isScrolling: boolean
  __isSmooth: true
  __isStopped: boolean
  actualScroll: number
  className: string
  isHorizontal: boolean
  isLocked: boolean
  isScrolling: boolean
  isSmooth: boolean
  isStopped: boolean
  limit: number
  progress: number
  scroll: number
}

export default class Scroll {
  lenis: Lenis
  navbar: gsap.core.Timeline
  constructor(page: string) {
    this.create(page)
  }

  create(page: string) {
    this.lenis = new Lenis({
      smoothTouch: page === "home" && innerWidth >= 768 ? true : false,
      // wrapper: innerWidth >= 768 ? window : window.$(".app"),
    })

    this.lenis.on("scroll", ScrollTrigger.update)
    this.lenis.scrollTo(0)
    gsap.ticker.lagSmoothing(0)
    requestAnimationFrame(this.raf.bind(this))

    this.lenis.on("scroll", (e: LenisEvent) => {
      if (e.direction > 0) {
        // if (isPlayingNav) return
        // isPlayingNav = true
        // gsap.delayedCall(1.5, () => {
        //   isPlayingNav = false
        // })
        this.navbar.play()
      }
      if (e.direction < 0) {
        // if (isReversingNav) return
        // isReversingNav = true
        // gsap.delayedCall(1.5, () => {
        //   isReversingNav = false
        // })
        this.navbar.reverse()
      }
    })

    this.navbar = gsap.timeline({ paused: true }).fromTo(
      "header",
      {
        y: "0rem",
        duration: 0.2,
      },
      {
        y: "-16rem",
        ease: "expo.in",
        duration: 0.75,
      }
    )
  }

  navigate(page: string) {
    this.lenis.reset()
    this.create(page)
  }

  raf(time: number) {
    this.lenis.raf(time)
    requestAnimationFrame(this.raf.bind(this))
  }
}
