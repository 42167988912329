import { CountUp } from "countup.js"
import gsap from "gsap"
import { Draggable, ScrollTrigger, Observer } from "gsap/all"
import { $App } from ".."
import OGL from "../classes/GL"
import Media from "../classes/Media"
import { InertiaPlugin } from "gsap-trial/all"

gsap.registerPlugin(Draggable, InertiaPlugin, Observer)

gsap.registerPlugin(Draggable, InertiaPlugin, ScrollTrigger)

export default class Home {
  app: $App
  ogl: OGL
  mechanics: NodeListOf<Element>
  medias: Media[]
  lenis: any
  constructor(app: $App) {
    this.app = app
  }

  create() {
    this.createEyes()
    this.createHero()
    this.createHeroAnime()
    this.createMech()
    this.createWhy()
    this.createCountUp()
  }

  createCountUp() {}

  createHeroAnime() {
    return
    const mm = gsap.matchMedia()
    let tl: gsap.core.Timeline
    tl = gsap.timeline()
    mm.add("(min-width:761px)", () => {
      tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".hero",
            pin: true,
            start: "0% 0%",
            end: "50% 0%",
            scrub: true,
            invalidateOnRefresh: true,
          },
        })
        .to(
          ".hero__pre, .hero__head, .hero__nose, .hero__eyeL, .hero__eyeR, .hero__stickers",
          {
            y: "-75rem",
            ease: "none",
          }
        )
        .to(
          ".hero__polaroid__media img",
          {
            opacity: 1,
          },
          0.05
        )
        .to(
          ".hero__polaroid",
          {
            // x: () => (Math.random() - 0.5) * 100,
            top: () => "20rem",
            left: (i) => `${i * 25 - 5}rem`,
            scale: 1,
            immediateRender: false,
            stagger: { amount: 0.15 },
          },
          0.05
        )
    })
    mm.add("(max-width:760px)", () => {
      tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".hero",
            pin: true,
            start: "0% 0%",
            end: "50% 0%",
            scrub: true,
            invalidateOnRefresh: true,
          },
        })
        .to(
          ".hero__pre, .hero__head, .hero__nose, .hero__eyeL, .hero__eyeR, .hero__stickers",
          {
            y: "-75rem",
            ease: "none",
          }
        )
        .to(
          ".hero__polaroid__media img",
          {
            opacity: 1,
          },
          0.05
        )
      // .to(
      //   ".hero__polaroid",
      //   {
      //     // x: () => (Math.random() - 0.5) * 100,
      //     left: () => "10rem",
      //     top: (i) => `${i * 10 - 5}rem`,
      //     scale: 1,
      //     immediateRender: false,
      //     stagger: { amount: 0.15 },
      //   },
      //   0.05
      // )
    })
  }

  createWhy() {
    const mm = gsap.matchMedia()
    let tl: gsap.core.Timeline
    tl = gsap.timeline()
    mm.add("(min-width:761px)", () => {
      tl = gsap
        .timeline({
          scrollTrigger: {
            trigger: ".why",
            pin: true,
            start: "-25% 0%",
            end: `+=${innerWidth * 0.3}`,
            scrub: true,
            once: true,
          },
        })
        .from(".why__body figure", {
          left: "52rem",
          top: "33.145rem",
          ease: "power2",
          stagger: { amount: -0.12, ease: "ease" },
        })

      new CountUp(window.$(".hero__stat.one h2 i"), 1900, {
        enableScrollSpy: true,
        duration: 3,
        startVal: 1000,
        useEasing: true,
        scrollSpyOnce: true,
      })

      new CountUp(window.$(".hero__stat.two h2 i"), 50000, {
        enableScrollSpy: true,
        startVal: 10000,
        duration: 3,
        useEasing: true,
        scrollSpyOnce: true,
      })

      new CountUp(window.$(".hero__stat.three h2 i"), 4000, {
        enableScrollSpy: true,
        duration: 3,
        startVal: 1000,
        useEasing: true,
        scrollSpyOnce: true,
      })
    })

    mm.add("(max-width:760px)", () => {
      tl = gsap.timeline({ delay: 5 })

      new CountUp(window.$(".hero__stat.one h2 i"), 1900, {
        enableScrollSpy: true,
        duration: 3,
        startVal: 1000,
        useEasing: true,
        scrollSpyOnce: true,
      })

      new CountUp(window.$(".hero__stat.two h2 i"), 50000, {
        enableScrollSpy: true,
        startVal: 10000,
        duration: 3,
        useEasing: true,
        scrollSpyOnce: true,
      })

      new CountUp(window.$(".hero__stat.three h2 i"), 4000, {
        enableScrollSpy: true,
        duration: 3,
        startVal: 1000,
        useEasing: true,
        scrollSpyOnce: true,
      })
    })

    return tl
  }

  createMech() {
    this.ogl = new OGL({ element: ".mech" })
    this.ogl.createGeometry()
    this.mechanics = document.querySelectorAll(".mechanic")
    this.medias = Array.from(this.mechanics).map((element) => {
      let media = new Media({
        element,
        geometry: this.ogl.planeGeometry,
        gl: this.ogl,
        scene: this.ogl.scene,
        screen: this.ogl.screen,
        viewport: this.ogl.viewport,
      })

      return media
    })
    const width = window.$(".mech__body").clientWidth
    const tl = gsap
      .timeline({
        repeat: -1,
        onUpdate: () => {
          this.medias.forEach((media) => {
            media.updateX((tl.progress() * width) / 1.968503937)
          })
        },
      })
      .fromTo(
        ".mech__body",
        { xPercent: 0 },
        {
          xPercent: innerWidth < 768 ? -50.55 : -50.8,
          duration: 50,
          ease: "none",
        }
      )

    if (innerWidth < 768) {
      tl.pause()
      Observer.create({
        target: ".mech__body",
        onDrag: (e) => {
          gsap.to(tl, {
            progress: Math.max(Math.min(tl.progress() - e.deltaX / 50, 1), 0),
            duration: 1,
            ease: "Power1.out",
          })
        },
        onWheel: (e) => {
          if (tl.progress() === 1) tl.progress(0)
          else if (tl.progress() === 0) tl.progress(1)
          gsap.to(tl, {
            progress: Math.max(Math.min(tl.progress() + e.deltaX / 50, 1), 0),
            duration: 1,
            ease: "Power1.out",
          })
        },
      })
    }
  }

  createEyes() {
    gsap.set(".hero__eyeL object, .hero__eyeR object", {
      xPercent: -50,
      yPercent: -50,
    })
    let xTo = gsap.quickTo(".hero__eyeL object, .hero__eyeR object", "x", {
        duration: 0.6,
        ease: "power3",
      }),
      yTo = gsap.quickTo(".hero__eyeL object, .hero__eyeR object", "y", {
        duration: 0.6,
        ease: "power3",
      })

    window.addEventListener("mousemove", (e) => {
      xTo((((e.clientX / innerWidth) * 29) / 1440) * innerWidth)
      yTo((((e.clientY / innerHeight) * 34) / 1440) * innerWidth)
    })

    // gsap.set(".preHero__follow", {
    //   opacity: 1,
    // })
    // xTo(innerWidth / 2)
    // yTo(innerHeight / 2)
  }

  createHero() {
    Draggable.create(".hero__polaroid", {
      inertia: true,
    })
  }

  resize() {
    if (this.medias) {
      this.medias.forEach((media) =>
        media.onResize({
          screen: this.ogl.screen,
          viewport: this.ogl.viewport,
        })
      )
    }
  }

  update() {
    if (this.medias) {
      this.medias.forEach((media) => media.update())
    }
  }

  destroy() {}

  navigate() {}
}
